import classNames from "classnames";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const MobileMenu = ({ isOpen, onClose }) => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick1 = (e) => {
    e.stopPropagation();
    setIsOpen1((prevState) => !prevState);
    if (isOpen2) {
      setIsOpen2(false);
    }
  };

  const handleClick2 = (e) => {
    e.stopPropagation();
    setIsOpen2((prevState) => !prevState);
    if (isOpen1) {
      setIsOpen1(false);
    }
  };

  const handleClick3 = (e) => {
    e.stopPropagation();
    setIsOpen3((prevState) => !prevState);
    if (isOpen2) {
      setIsOpen2(false);
    }
  };

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
  };

  return (
    <div className="relative">
      <motion.div
        className={classNames("fixed inset-0 bg-black/50 z-[100] top-[72px]", {
          hidden: !isOpen || !isMobile,
        })}
        onClick={onClose}
      ></motion.div>

      <motion.ul
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: {
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05,
            },
          },
          closed: {
            y: -300,
            opacity: 0,
            transition: {
              type: "spring",
              duration: 1,
            },
          },
        }}
        className={classNames(
          "fixed pt-[72px] w-full font-normal flex flex-col bg-white dark:bg-black/90 gap-[20px] items-center p-4 border-y-[1px] border-gray-100 md:mt-0 md:border-0 z-[150]",
          { hidden: !isMobile }
        )}
      >
        <motion.li variants={itemVariants} className="relative group">
          <a href="/" className="block px-4 py-2 text-xl text-dark dark:text-white" aria-current="page">
            {t("header.home")}
          </a>
        </motion.li>
        <motion.li variants={itemVariants} className="relative group">
          <p
            className="flex items-center justify-between py-2 text-xl cursor-pointer text-dark dark:text-white"
            onClick={handleClick1}
          >
            {t("header.exchange.1")}
            <span className="pl-3">
              <svg width="25" height="24" viewBox="0 0 25 24">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.29289 8.8427C6.68342 8.45217 7.31658 8.45217 7.70711 8.8427L12 13.1356L16.2929 8.8427C16.6834 8.45217 17.3166 8.45217 17.7071 8.8427C18.0976 9.23322 18.0976 9.86639 17.7071 10.2569L12 15.964L6.29289 10.2569C5.90237 9.86639 5.90237 9.23322 6.29289 8.8427Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </p>
          <div className={isOpen1 ? "block" : "hidden"}>
            <a
              href={"https://wallet.newreality.club/stock"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              {t("header.exchange.2")}
            </a>
            <a
              href={"https://wallet.newreality.club/p2p/offers"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              {t("header.exchange.3")}
            </a>
          </div>
        </motion.li>
        <motion.li variants={itemVariants} className="relative group">
          <p
            className="flex items-center justify-between py-2 text-xl cursor-pointer text-dark dark:text-white"
            onClick={handleClick3}
          >
            {t("header.services")}
            <span className="pl-3">
              <svg width="25" height="24" viewBox="0 0 25 24">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.29289 8.8427C6.68342 8.45217 7.31658 8.45217 7.70711 8.8427L12 13.1356L16.2929 8.8427C16.6834 8.45217 17.3166 8.45217 17.7071 8.8427C18.0976 9.23322 18.0976 9.86639 17.7071 10.2569L12 15.964L6.29289 10.2569C5.90237 9.86639 5.90237 9.23322 6.29289 8.8427Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </p>
          <div className={isOpen3 ? "block" : "hidden"}>
            <a
              href={"https://wallet.newreality.club"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              {t("header.servicesItem.1")}
            </a>
            <a
              href={"https://wallet.newreality.club/stock"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              {t("header.servicesItem.10")}
            </a>
            <a
              href={"https://newreality.exchange"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              {t("header.servicesItem.3")}
            </a>
            <a href={"/Forvalidators"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.servicesItem.5")}
            </a>
            <a href={"/CryptoATMs"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.servicesItem.9")}
            </a>
            <a href={"/Createtoken"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.servicesItem.8")}
            </a>
            <a href={"/popularTokens"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.servicesItem.11")}
            </a>
            <a href={"/Presale"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.servicesItem.12")}
            </a>
            <a href={"/Merchant"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.servicesItem.13")}
            </a>
            <a
              href={"https://explorer.newreality.club"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              {t("header.servicesItem.6")}
            </a>
          </div>
        </motion.li>

        <motion.li variants={itemVariants} className="relative group" onClick={handleClick2}>
          <p className="flex items-center justify-between py-2 text-xl cursor-pointer text-dark dark:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0">
            {t("header.knowledge")}
            <span className="pl-3">
              <svg width="25" height="24" viewBox="0 0 25 24">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.29289 8.8427C6.68342 8.45217 7.31658 8.45217 7.70711 8.8427L12 13.1356L16.2929 8.8427C16.6834 8.45217 17.3166 8.45217 17.7071 8.8427C18.0976 9.23322 18.0976 9.86639 17.7071 10.2569L12 15.964L6.29289 10.2569C5.90237 9.86639 5.90237 9.23322 6.29289 8.8427Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </p>
          <div className={isOpen2 ? "block" : "hidden"}>
            <a href={"/TermsPage"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.knowledgeItem.1")}
            </a>
            <a href={"/СlassifyingPage"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.knowledgeItem.2")}
            </a>
            <a href={"/ARTPage"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.knowledgeItem.3")}
            </a>
            <a href={"/EmtPage"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.knowledgeItem.4")}
            </a>
            <a href={"/UnboundPage"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.knowledgeItem.5")}
            </a>
            <a href={"/RegulationPage"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.knowledgeItem.6")}
            </a>
            <a href={"/Createtoken"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.knowledgeItem.7")}
            </a>
            <a href={"/MerchantConfig"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.knowledgeItem.8")}
            </a>
            <a
              href={"https://docs.newreality.bond/api/"}
              className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3"
            >
              {t("header.knowledgeItem.9")}
            </a>
            <a href={"/TariffsPage"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.knowledgeItem.11")}
            </a>
            <a href={"/ExchangeDesc"} className="block rounded py-2.5 text-sm text-dark dark:text-white lg:px-3">
              {t("header.knowledgeItem.12")}
            </a>
          </div>
        </motion.li>
        <motion.li variants={itemVariants} className="relative group">
          <a className="p-[2px] relative w-20 block" href="https://wallet.newreality.club">
            <div className="px-2 py-2 xl:py-4 xl:px-8 text-center inset-0 bg-blue-600 rounded-lg relative group transition duration-300 ease-in-out text-white zoom">
              Sing in
            </div>
          </a>
        </motion.li>
      </motion.ul>
    </div>
  );
};

export default MobileMenu;
