import { useTheme } from "../../providers/ThemeProvider";

import { Dropdown } from "flowbite-react";
import { IconContext } from "react-icons";
import { GrLanguage } from "react-icons/gr";

import cz from "../../images/flags/Cz.svg";
import kz from "../../images/flags/Kz.svg";
import de from "../../images/flags/De.svg";
import fr from "../../images/flags/Fr.svg";
import pl from "../../images/flags/Pl.svg";
import ru from "../../images/flags/Ru.svg";
import usa from "../../images/flags/Usa.svg";
import china from "../../images/flags/China.svg";
import In from "../../images/flags/In.svg";
import it from "../../images/flags/It.svg";
import pt from "../../images/flags/Pt.svg";
import sa from "../../images/flags/Sa.svg";
import sp from "../../images/flags/Sp.svg";
import uk from "../../images/flags/Uk.svg";
import { motion } from "framer-motion";
import { changeLanguage } from "../../i18n";
const LangSwitcher = () => {
  const { theme } = useTheme();
  const color1 = theme === "dark" ? "white" : "black";

  const changelng = async (lng) => {
    await changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
  };

  return (
    <IconContext.Provider value={{ className: "cursor-pointer", color: color1, size: "1.5rem" }}>
      <Dropdown
        label="Language"
        dismissOnClick={true}
        placement="bottom"
        renderTrigger={() => (
          <motion.div whileTap={{ scale: 0.9 }}>
            <GrLanguage />
          </motion.div>
        )}
      >
        <Dropdown.Item onClick={() => changelng("en")}>
          <img className="w-5 h-5 mr-2" src={usa} alt="en" />
          English
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("cs")}>
          <img className="w-5 h-5 mr-2" src={cz} alt="cs" />
          Czech
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("pl")}>
          <img className="w-5 h-5 mr-2" src={pl} alt="pl" />
          Polish
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("de")}>
          <img className="w-5 h-5 mr-2" src={de} alt="de" />
          German
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("fr")}>
          <img className="w-5 h-5 mr-2" src={fr} alt="fr" />
          French
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("it")}>
          <img className="w-5 h-5 mr-2" src={it} alt="it" />
          Italy
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("es")}>
          <img className="w-5 h-5 mr-2" src={sp} alt="es" />
          Spanish
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("pt")}>
          <img className="w-5 h-5 mr-2" src={pt} alt="pt" />
          Portuguese
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("uk")}>
          <img className="w-5 h-5 mr-2" src={uk} alt="uk" />
          Ukrainian
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("kk")}>
          <img className="w-5 h-5 mr-2" src={kz} alt="kk" />
          Kazakh
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("ru")}>
          <img className="w-5 h-5 mr-2" src={ru} alt="ru" />
          Russian
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("zh")}>
          <img className="w-5 h-5 mr-2" src={china} alt="china" />
          Chinese
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("ar")}>
          <img className="w-5 h-5 mr-2" src={sa} alt="Arabic" />
          Arabic
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changelng("hi")}>
          <img className="w-5 h-5 mr-2" src={In} alt="hi" />
          Hindi
        </Dropdown.Item>
      </Dropdown>
    </IconContext.Provider>
  );
};

export default LangSwitcher;
